<template>
    <layout class="box">
        <div class="container mobile-bread mt15 flex x-left">
            <div class="color999">您所在的位置：</div>
            <a-breadcrumb>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{ path: '/special-ma/index/'+$route.params.id }">首页</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>相关机构</a-breadcrumb-item>
            </a-breadcrumb>
        </div>
        <a-skeleton active :loading="loading">
            <div class="content">
                <div class="flex flex-wrap x-left y-top" v-if="dataList.length">
                    <div class="item flex" v-for="(item,index) in dataList" :key="index" @click="orgJumpPage(item.id)">
                        <div class="top-img flex-item">
                            <img :src="item.img | urlFilter(250,'avatar') || require('@/assets/images/committee-logo.jpg')" alt="">
                            <div class="title row-2">{{item.title}}</div>
                        </div>
                    </div>
                </div>
                <a-empty style="margin-top:100px;" v-if="!dataList.length && !loading" />
                <div class="t-c mt20">
                    <a-pagination :total="total" v-model:current="params.page"
                                    :page-size="params.limit" :show-size-changer="false" show-quick-jumper
                                    :hideOnSinglePage="true"  @change="getAllList"
                        @showSizeChange="handleChangeSize" />
                </div>
            </div>
        </a-skeleton>
    </layout>
</template>

<script>
    import layout from '@/components/layout/index-ma';
    import storage from 'store';
    import { committee_id } from '@/config';
    export default {
        name: "SpecialMaOrgList",
        components : {
            layout
        },
        data(){
            return {
                params : {
                    limit: 12, 
                    topic_id: this.$route.params.id, 
                    page: 1,
                    committee_id
                },
                total:0,
                dataList : [],
                loading : true,
            }
        },
        created() {
          this.getAllList();
        },
        methods :{
            getAllList(){
                this.loading = true;
                this.request.post('TopicOrgList',this.params).then(res => {
                    this.dataList = res.data.list;
                    this.total = Number(res.data.count);
                    this.loading = false;
                })
            },
            getList(){
                let { page , limit } = this.params;
                let data = this.allDataList.slice((page - 1) * limit, page * limit);
                this.dataList = data;
            },
            handleChangeSize(size){
                this.params.page = size;
                this.getAllList();
            },
            orgJumpPage(id) {
                this.$router.push({ 
                    path: '/special-ma/org-detail/'+this.$route.params.id,
                    query: {
                        id
                    }
                });
            },
        }
    }
</script>

<style scoped lang="less">
    .content{
        width: 1240px;
        min-height: 798px;
        background: #FFFFFF;
        border-radius: 10px;
        padding: 20px;
        margin: 0 auto;
        align-content: flex-start;
        .item{
            width: 285px;
            height: 160px;
            border-radius: 10px;
            border: 1px solid #EFF4FF;
            margin-right: 20px;
            margin-bottom: 20px;
            cursor: pointer;
            transition: .3s;
            &:nth-child(4n){
                margin-right: 0;
            }
            .top-img{
                position: relative;
                img{
                    position: absolute;
                    width: 84px;
                    top: 20px;
                    right: 0;
                    left: 0;
                    margin: auto;
                }
                .title{
                    color: #333333;
                    text-align: center;
                    position: absolute;
                    bottom: 20px;
                    width: 100%;
                }
            }
            &:hover{
                border-color: rgba(0,0,0,.09);
                box-shadow: 0 2px 8px rgba(0,0,0,.09);
            }
        }
    }
    @media screen and (min-width:651px) and (max-width:768px){
        .content{
            width: 100%;
            margin: 0;
            padding: 10px;
            .item{
                margin-right: 10px!important;
                width: calc((100% - 20px) / 3);
                &:nth-child(3n+3){
                    margin-right: 0!important;
                }
            }
        }
    }
    @media screen and (min-width:550px) and (max-width:650px){
        .content{
            width: 100%;
            margin: 0;
            padding: 10px;
            .item{
                margin-right: 10px!important;
                width: calc((100% - 10px) / 2);
                &:nth-child(2n+2){
                    margin-right: 0!important;
                }
            }
        }
    }
    @media screen and (max-width:549px){
        .content{
            width: 100%;
            margin: 0;
            padding: 10px;
            .item{
                margin-right: 0!important;
                width: 100%;
            }
        }
    }
</style>